/*==================== GOOGLE FONTS ====================*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

/*==================== VARIABLES CSS ====================*/
:root {
    --header-height: 3rem;

    /*========== Colors ==========*/
    /* Change favorite color */

    /* HSL color mode */
    --first-color:   #ccc9dc;
    --button-color:#495057;
    --first-color-second: #ccc9dc;
    --first-color-alt: #DC143C;
    --first-color-lighter: hsl(var(--hue-color), 92%, 85%);
    --title-color: hsl(var(--hue-color), 8%, 15%);
    --text-color: hsl(var(--hue-color), 8%, 45%);
    --text-color-light: hsl(var(--hue-color), 8%, 65%);
    --input-color: hsl(var(--hue-color), 70%, 96%);
    --body-color: hsl(var(--hue-color), 60%, 99%);
    --container-color: #fff;
    --scroll-bar-color: hsl(var(--hue-color), 12%, 90%);
    --scroll-thumb-color: hsl(var(--hue-color), 12%, 80%);
    

    /*========== Font and typography ==========*/
    --body-font: 'Poppins', sans-serif;

    /* .5rem = 8px, 1rem = 16px, 1.5rem = 24px ... */
    --big-font-size: 2rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1.125rem;
    --normal-font-size: .938rem;
    --small-font-size: .813rem;
    --smaller-font-size: .75rem;

    /*========== Font weight ==========*/
    --font-medium: 500;
    --font-semi-bold: 600;

    /*========== Margenes Bottom ==========*/
    /* .25rem = 4px, .5rem = 8px, .75rem = 12px ... */
    --mb-0-25: .25rem;
    --mb-0-5: .5rem;
    --mb-0-75: .75rem;
    --mb-1: 1rem;
    --mb-1-5: 1.5rem;
    --mb-2: 2rem;
    --mb-2-5: 2.5rem;
    --mb-3: 3rem;

    /*========== z index ==========*/
    --z-tooltip: 10;
    --z-fixed: 100;
    --z-modal: 1000;
}

/* Font size for large devices */
@media screen and (min-width: 968px) {
    :root {
        --big-font-size: 3rem;
        --h1-font-size: 2.25rem;
        --h2-font-size: 1.5rem;
        --h3-font-size: 1.25rem;
        --normal-font-size: 1rem;
        --small-font-size: .875rem;
        --smaller-font-size: .813rem;
    }
}

/*========== Variables Dark theme ==========*/


/*========== Button Dark/Light ==========*/


/*==================== BASE ====================*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
*:focus {
  outline: none;
}
*{
  -webkit-tap-highlight-color: transparent;
}


html {
  scroll-behavior: smooth;
}

body {
  margin: 0 0 var(--header-height) 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: var(--text-color);
}

h1, h2, h3, h4 {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

/*==================== REUSABLE CSS CLASSES ====================*/
.section {
  padding: 2rem 0 4rem;
}

.section_title {
  font-size: var(--h1-font-size);
  color: var(--title-color);
}

.section_subtitle {
  display: block;
  font-size: var(--small-font-size);
  margin-bottom: var(--mb-3);
}

.section_title, 
.section_subtitle {
  text-align: center;
}

/*==================== LAYOUT ====================*/
.container {
  max-width: 768px;
  margin-left: var(--mb-1-5);
  margin-right: var(--mb-1-5);
}

.grid {
  display: grid;
  gap: 1.5rem;
}

.header {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: var(--z-fixed);
  background-color: var(--body-color);
}

/*==================== NAV ====================*/
.nav{
  max-width: 968px;
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav_logo,
.nav_toggle{
  color: var(--title-color);
  font-weight: var(--font-medium);
}

.nav_logo:hover{
  color: var(--first-color);
}

.nav_toggle{
  font-size: 1.1rem;
  cursor: pointer;
}

.nav_toggle:hover{
  color: var(--first-color);
}

@media screen and (max-width: 767px){
  .nav_menu{
    position: fixed;
    bottom: -100%;
    left:0;
    width: 100%;
    background-color:  var(--first-color);
    padding:  2rem 1.5rem 4rem;
    box-shadow: 0 -1px 4px rgba(0,0,0,.15);
    border-radius: 1.5rem 1.5rem 0 0;
    transition: bottom 0.4s ease;
  }
  .nav_logo{
    visibility: hidden;
  }

}

.nav_list{
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.nav_link{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--small-font-size);
  color: var(--title-color);
  font-weight: var(--font-medium);

}

.nav_link:hover{
  color: var(--first-color-alt);
}

.nav_icon{
  font-size: 1.2rem;

}

.nav_close{
  position: absolute;
  right: 1.3rem;
  bottom: .5rem;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--button-color);
}

.nav_close:hover{
  color: var(--first-color-alt);
}

/* show menu */
.show_menu{
  bottom: 0;
}



/*==================== HOME ====================*/
.home_container{
  gap: 1rem;
}

.home_content{
  grid-template-columns: .5fr 3fr;
  padding-top: 3.5rem;
  align-items: center;
}

.home_social{
  display: grid;
  grid-template-columns: max-content;
  row-gap: 1rem;
}

.home_social-icon{
  font-size: 1.25rem;
  color: var(--button-color);
}

.home_social-icon:hover{
  color: var(--first-color-alt);
}

.home_blob{
  width:200px;
  fill: var(--first-color);
}

.home_blob-img{
  width:190px;

}

.home_data{
  grid-column: 1/3;
}

.home_title{
  font-size: var(--big-font-size);
}

.home_subtitle{
  font-size:var(--h3-font-size);
  color: var(--text-color);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-0-75);
}

.home_description{
  margin-bottom:var(--mb-2) ;
}

.home_scroll{
  display: none;
}

.home_scroll-button{
  color: var(--first-color-second);
  transition: .3s;
}

.home_scroll-button:hover{
  transform: translateY(.25rem);
}

.home_scroll-mouse{
  font-size: 2rem;
}

.home_scroll-name{
  font-size: var(--small-font-size);
  color: var(--title-color);
  font-weight: var(--font-medium);
  margin-right: var(--mb-0-25);
}

.home_scroll-arrow{
  font-size: 1.25rem;
}


/*==================== BUTTONS ====================*/
.button{
  display: inline-block;
  background-color: var(--button-color);
  color: #fff;
  padding: 1rem;
  border-radius: .5rem;
  font-weight: var(--font-medium);
}
.button:hover{
  background-color: #f4f4f4;
  color: #495057;
}

.button_icon{
  font-size: 1.25rem;
  margin-left: var(--mb-0-5);
  transition: .3s;
}

.button--white{
  background-color:white;
  color: var(--button-color);
  transition: .3s;
}
.button--white:hover{
  background-color: white;
}


.button--flex{
  display: inline-flex;
  align-items: center;
}
.success{
  background-color: #AEF359;
  color: #495057;
}
.success:hover{
  background-color: #AEF359;
}
.contact_button{
  position: relative;
}
.button_hidden{
  position: absolute;
  opacity: 0;
  transform: translateY(20px);
  pointer-events: none;

}
.button_visible{
  opacity: 1;
  transform:translateY(0) ;
  transition: opacity 0.5s ease, transform 0.5s ease;
}
.contact-button button{
  border: none;

}
.loading{
  background-color: #f4f4f4;
  color: #495057;
}
.loading_icon{
  width: 15px;
}
/*==================== ABOUT ====================*/
.about_img{
  width: 200px;
  border-radius: .5rem;
  justify-self: center;
  align-self: center;
}

.about_description{
  text-align: center;
  margin-bottom: var(--mb-2-5);
}

.about_info{
  display: flex;
  justify-content: space-evenly;
  margin-bottom: var(--mb-2-5);
}

.about_info-title{
  font-size: var(--h2-font-size);
  font-weight: var(--font-semi-bold);
  color: var(--title-color);
}

.about_info-name{
  font-size: var(--smaller-font-size);
}

.about_info-title,
.about_info-name{
  display: block;
text-align: center;
}

.about_buttons{
  display: flex;
  justify-content: center;
}

/*==================== SKILLS ====================*/
.skills_container{
  row-gap: 0;
}

.skills_header{
  display: flex;
  align-items: center;
  margin-bottom: var(--mb-1);
  cursor: pointer;
}

.skills_icon,
.skills_arrow{
  font-size: 2rem;
  color: var(--first-color);
}

.skills_icon{
  margin-right: var(--mb-0-75);
}

.skills_title{
  font-size: var(--h3-font-size);
}

.skills_subtitle{
  font-size: var(--small-font-size);
  color: var(--text-color-light);
}

.skills_arrow{
  margin-left: auto;
  transform: .4s;
}

.skills_list{
  row-gap: 1.5rem;
  padding-left: 2.7rem;
}

.skills_title{
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--mb-0-5);

}

.skills_name{
  font-size:var(--normal-font-size);
  font-weight: var(--font-medium);
}

.skills_bar,
.skills_percentage{
  height: 5px;
  border-radius: .25rem;
}

.skills_bar{
  background-color: var(--first-color-lighter);
}

.skills_percentage{
  display: block;
  background-color: var(--first-color);
}

.skills_html{
  width: 90%;
}
.skills_css{
  width: 80%;
}
.skills_js{
  width: 60%;
}
.skills_react{
  width: 85%;
}

.skills_python{
  width: 80%;
}
.skills_node{
  width: 70%;
}
.skills_firebase{
  width: 60%;
}
.skills_next{
  width: 85%;
}

.skills_figma{
  width: 90%;
}.skills_sketch{
  width: 70%;
}.skills_photoshop{
  width: 60%;
}

.skills_close .skills_list{
  height: 0;
  overflow: hidden;
  transition: ease 0.3s;
}

.skills_open .skills_list{
  height: max-content;
  margin-bottom: var(--mb-2-5);
  transition: ease 0.3s;
}

.skills_open .skills_arrow{
  transform: rotate(-180deg);
}


/*==================== PORTFOLIO ====================*/
.portfolio_container{
  overflow: initial;
}

.swiper-wrapper{
  text-align: center;
}

.portfolio_content{
  padding: 0 1.5rem;
}

.portfolio_img{
  border: #495057 solid;
  box-shadow: 1px 1px 4px #888888;
  width: 265px;
  border-radius: .5rem;
  justify-self: center;
}

.portfolio_title{
  margin-top: var(--mb-0-75);
  font-size: var(--h3-font-size);
  margin-bottom: var(--mb-0-5);
}

.portfolio_description{
  margin-bottom: var(--mb-0-75);
}

.portfolio_button:hover .button_icon{
  transform: translateX(.25rem);
}

.mySwiper .swiper-button-next{
  color: var(--first-color);
 
}
.mySwiper .swiper-button-prev{
  color: var(--first-color); 
}
.swiper{
  overflow: visible !important;
}

.mySwiper .swiper-pagination{

  bottom: -2.5rem !important;
}


/*==================== PROJECT IN MIND ====================*/
.project{
  text-align: center;
}

.project_bg{
  background-color: #f4f4f4;
  padding-top: 3rem;
  padding-bottom:3rem ;
}

.project_title{
  font-size: var(--h2-font-size);
  margin-bottom: var(--mb-0-75);
}

.project_description{
  margin-bottom: var(--mb-1-5);
}

.project_title,
.project_description{
  color: black;
}

#project-img{
  width: 100px;
  height: 100px;
  justify-self: center;
  color: #495057;
}


/*==================== CONTACT ME ====================*/
.contact_container{
  row-gap: 3rem;
}


.contact_information{
  display: flex;
  margin-bottom: var(--mb-2);
}

.contact_icon{
  font-size: 2rem;
  color:gray;
  margin-right: var(--mb-0-75);
}

.contact_title{
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
}

.contact_subtitle{
  font-size: var(--small-font-size);
  color:var(--text-color-light)
}

.contact_content{
  background-color: #f4f4f4;
  border-radius: .5rem;
  padding: .75rem 1rem .25rem;
}

.contact_label{
  font-size: var(--smaller-font-size);
  color: var(--title-color);
}

.contact_input{
  width: 100%;
  background-color: var(--input-color);
  color: var(--text-color);
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  border: none;
  outline: none;
  padding: .25rem .5rem .5rem 0;
}
/*==================== FOOTER ====================*/
.footer{
  padding-top: 2rem;
}

.footer_container{
  row-gap: 3.5rem;
}

.footer_bg{
  background-color: #495057;
  padding: 2rem 0 3rem;
}

.footer_title{
  font-size: var(--h1-font-size);
  margin-bottom: var(--mb-0-25);
}

.footer_subtitle{
  font-size: var(--small-font-size);
}

.footer_links{
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}

.footer_link:hover{
  display: var(--first-color-lighter);
}

.footer_social{
  font-size: 1.25rem;
  margin-right: var(--mb-1-5);
}

.footer_social:hover{
  color: var(--first-color-lighter);
}

.footer_copy{
  font-size: var(--smaller-font-size);
  text-align: center;
  color: white;
  margin-top: var(--mb-3);
}

.footer_title,
.footer_subtitle,
.footer_link,
.footer_social{
  color: white;
}
/*========== SCROLL UP ==========*/


/* Show scroll */


/*========== SCROLL BAR ==========*/
::-webkit-scrollbar{
  width: .60rem;
  background-color: var(--scroll-bar-color);
  border-radius: .5rem;
}

::-webkit-scrollbar-thumb{
  background-color: var(--scroll-thumb-color);
  border-radius: .5rem;
}

/*==================== MEDIA QUERIES ====================*/
/* For small devices */
@media screen and (max-width: 350px){
  .container{
    margin-left: var(--mb-1);
    margin-right: var(--mb-1);
  }

  .nav_menu{
    padding: 2rem .25rem 4rem;
  }
  .nav_list{
    column-gap: 0;
  }

  .home_content{
    grid-template-columns: .25fr 3fr;
  }

  .home_blob{
    width: 180px;
  }

  .skills_title{
    font-size: var(--normal-font-size);
  }

  .project_img{
    width: 200px;
  }

}

/* For medium devices */
@media screen and (min-width: 568px){
  .home_content{
    grid-template-columns: max-content 1fr 1fr;
  }

  .home_data{
    grid-column: initial;
  }

  .home_img{
    order: 1;
    justify-self: center;
  }

  .about_container,
  .skills_container,
  .portfolio_content,
  .contact_container,
  .project_container
  .footer_container{
    grid-template-columns: repeat(2, 1fr);
  }
}

/* For large devices */
@media screen and (min-width:768px) {
  .container{
    margin-left: auto;
    margin-right: auto;
  }

  body{
    margin: 0;
  }

  .section{
    padding: 6rem 0 2rem;
  }

  .section_subtitle{
    margin-bottom: 4rem;
  }

  .header{
    top: 0;
    bottom: initial;
    background-color: white;
  }

  .header,
  .main,
  .footer_container{
    padding: 0 1rem ;
  }

  .nav{
    height: calc(var(--header-height)+1.5rem);
    column-gap: 1rem;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .nav_icon,
  .nav_close,
  .nav_toggle{
    display: none !important;
  }

  .nav_list{
    display: flex;
    column-gap: 2em;
  }
  .nav_menu{
    margin-left: auto;
  }
  
  .home_container{
    row-gap: 5rem;
  }

  .home_content{
    padding-top: 5.5rem;
    column-gap: 2rem;
  }

  .home_blob{
    width:270px ;
  }

  .home_scroll{
    display: block;
  }

  .home_scroll-button{
    margin-left: 3rem;
  }

  .about_container{
    column-gap: 5rem;
  }

  .about_img{
    width: 350px;
  }
  
  .about_description{
    text-align: initial;
  }

  .about_info{
    justify-content: space-between;
  }

  .about_buttons{
    justify-content: initial;
  }

  .portfolio_img{
    width: 320px;
  }

  .portfolio_content{
    align-items: center;
  }

  .project{
    text-align: initial;
  }

  .project_bg{
    background: none;
  }

  .project_container{
    background-color: #f4f4f4;
    border-radius: 1rem;
    padding: 3rem 2.5rem 0;
    grid-template-columns: 1fr max-content;
    column-gap: 3rem;
  }

  .project_data{
    padding-top: .8rem;
    padding-bottom: .8rem;
  }

  .footer_container{
    grid-template-columns: repeat(3, 1fr);
  }

  .footer_bg{
    padding: 3rem 0 3.5rem;
  }

  .footer_links{
    flex-direction: row;
    column-gap: 2rem;
  }

  .footer_socials{
    justify-self: flex-end;
  }

  .footer_copy{
    margin-top: 4.5rem;
  }
}

/* for larger devices */
@media screen and (min-width:1024px){
  .header,
  .main,
  .footer_container{
    padding: 0;
  }
  
  .home_blob{
    width: 320px;
  }

  .home_social{
    transform: translateX(-6rem);
  }

  .portfolio_content{
    column-gap: 5rem;
  }

  .swiper-portfolio-icon{
    font-size: 3.5rem;
  }

  .swiper-button-prev{
    left: -3.5rem;
  }

  .swiper-button-next{
    right: -3.5rem;
  }

  .swiper-container-horizontal > .swiper-pagination-bullets{
    bottom: -4.5rem;
  }

  .contact_form{
    width: 460px;
  }

  .contact_inputs{
    grid-template-columns: repeat(2, 1fr);
  }
}